import React from 'react';
import Tile from './Tile';
import './Board.css';

const Board = ({ puzzle, onTileClick, isScrambling, flashingTiles }) => {
  return (
    <div className="board">
      {puzzle.map((letter, index) => (
        <div
          key={index}
          className={`tile ${letter === '' ? 'empty' : ''} ${flashingTiles.includes(index) ? 'flashing' : ''}`}
          onClick={() => onTileClick(index)}
        >
          {letter}
        </div>
      ))}
    </div>
  );
};

export default Board;