import React, { useState, useEffect, useRef } from 'react';
import Board from './components/Board';
import StaticGrid from './components/StaticGrid';
import wordList from './wordList';
import './App.css';

const ScalingWord = ({ word }) => {
  const scaleFactor = Math.max(1, 1 + (word.length - 4) * 0.05);
  return (
    <span 
      className="word-pill" 
      style={{ 
        fontSize: `${scaleFactor}em`,
        display: 'inline-flex',
        alignItems: 'center',
      }}
    >
      {word}
    </span>
  );
};

const PulsingWord = ({ word, isPulsing }) => {
  return (
    <div className={`pulsing-word-container ${isPulsing ? 'pulsing' : ''}`}>
      <ScalingWord word={word} />
    </div>
  );
};

function App() {
  // State to hold the current puzzle state
  const [puzzle, setPuzzle] = useState([]);
  // State to track the number of moves
  const [moves, setMoves] = useState(200); // Start at 200 moves
  const [startMoves, setStartMoves] = useState(0); // Start at 200 moves
  // State to track the used word
  const [usedWords, setUsedWords] = useState([]);
  // State to track found words
  const [foundWords, setFoundWords] = useState([]);
  // State to track points
  const [points, setPoints] = useState(0);
  // State to track scrambling
  const [isScrambling, setIsScrambling] = useState(false);
  // State to track flashing tiles
  const [flashingTiles, setFlashingTiles] = useState([]);
  // State to track pulsing points
  const [isPulsing, setIsPulsing] = useState(false);
  // State to track game over
  const [isGameOver, setIsGameOver] = useState(false);
  // State to track new words
  const [newWords, setNewWords] = useState([]);
  // State to track dark mode
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedMode = localStorage.getItem('isDarkMode');
    return savedMode !== null ? JSON.parse(savedMode) : true;
  });
  const [showRules, setShowRules] = useState(true);
  const [allWordsFound, setAllWordsFound] = useState(false);
  const [isMovesShaking, setIsMovesShaking] = useState(false);
  const [isMovePulsing, setIsMovePulsing] = useState(false);
  const [isAnimatingMoves, setIsAnimatingMoves] = useState(false);
  const [isCheckingGameOver, setIsCheckingGameOver] = useState(false);
  const [isGameStarted, setIsGameStarted] = useState(false);
  const [isCodefaceJumping, setIsCodefaceJumping] = useState(false);
  const [isCodefaceShaking, setIsCodefaceShaking] = useState(false);
  const [assistantText, setAssistantText] = useState("");
  const fullTextRef = useRef("");
  const currentIndexRef = useRef(0);
  const [isAssistantVisible, setIsAssistantVisible] = useState(false);
  const [currentCodeface, setCurrentCodeface] = useState("determined");
  const isWordAnimationPlayingRef = useRef(false);

  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

  const [lastMessageMove, setLastMessageMove] = useState(200);
  const [lastRandomMessage, setLastRandomMessage] = useState("");

  const randomMessages = [
    { codeface: "rand1", text: "POINTS? PLEASE?" },
    { codeface: "rand2", text: "INTERESTING MOVE..." },
    { codeface: "rand3", text: "I SPOT A WoooOOORD!" },
    { codeface: "rand4", text: "BRB MAKING COFFEE" },
    { codeface: "rand5", text: "ROOTING FOR YOU!" },
    { codeface: "rand6", text: "LOVING THIS GAME ᡣ𐭩₊ ⊹" },
    { codeface: "rand7", text: "NEED A MINUTE?" },
    { codeface: "rand8", text: "GO <USERNAME> GO!" },
  ];

  const triggerRandomMessage = () => {
    const availableMessages = randomMessages.filter(
      (msg) => msg.codeface !== lastRandomMessage
    );
    const randomMessage =
      availableMessages[Math.floor(Math.random() * availableMessages.length)];

    triggerAssistantMessage(randomMessage.codeface, randomMessage.text, "jump");
    setLastRandomMessage(randomMessage.codeface);
  };

  useEffect(() => {
    // Trigger a random message every 10-25 moves
    if (!isWordAnimationPlayingRef.current && moves !== 30 && moves !== 10 && isGameStarted && moves > 0 && lastMessageMove - moves >= Math.floor(Math.random() * (25 - 10 + 1) + 10)) {
      triggerRandomMessage();
      setLastMessageMove(moves);
    }
  }, [moves, isGameStarted]);

  // Function to create a puzzle with letters
  const createPuzzle = () => {
    let letters = [];
    let words = [];

    const addWord = (word) => {
      const wordLetters = word.split('');
      const newLetters = [...letters];
      
      wordLetters.forEach(letter => {
        // Randomly decide whether to add a duplicate letter
        if (!letters.includes(letter) || Math.random() < 0.5) {
          newLetters.push(letter);
        }
      });

      if (newLetters.length <= 15) {
        letters = newLetters;
        words.push(word);
        return true;
      }
      return false;
    };

    // Filter wordList to only include valid words
    const validWords = wordList.filter(word => 
      word.length >= 4 && 
      word.length <= 15
    );

    // Select words
    while (letters.length < 15 && words.length < 5) {
      const word = validWords[Math.floor(Math.random() * validWords.length)].toUpperCase();
      if (!words.includes(word) && addWord(word)) {
        continue;
      }
    }

    // Fill remaining spaces with random letters
    while (letters.length < 15) {
      const randomLetter = alphabet[Math.floor(Math.random() * alphabet.length)];
      letters.push(randomLetter);
    }

    // Add empty tile
    letters.push('');

    setUsedWords(words);
    return letters;
  };

  // Function to shuffle the puzzle
  const shufflePuzzle = (array) => {
    let currentIndex = array.length;
    let temporaryValue, randomIndex;

    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  };

  // Function to check for valid words following the spiral pattern
  const checkForWords = (puzzleArray, isGameOverCheck = false) => {
    const newFoundWords = [];
    const spiralOrder = [0, 1, 2, 3, 7, 11, 15, 14, 13, 12, 8, 4, 5, 6, 10, 9];
    
    const calculatePoints = (wordLength, letterIndex) => {
      if (letterIndex < 4) return 1;
      if (letterIndex < 10) return 2;
      if (letterIndex < 14) return 3;
      return 5;
    };

    const checkWord = (word, indices) => {
      if (word.length >= 4 && wordList.includes(word.toLowerCase()) && !foundWords.includes(word)) {
        newFoundWords.push({ word, indices });
      }
    };
    
    // Check words following the spiral pattern
    let word = '';
    let indices = [];
    for (let i = 0; i < spiralOrder.length; i++) {
      const letter = puzzleArray[spiralOrder[i]];
      if (letter === '') break;
      word += letter;
      indices.push(spiralOrder[i]);
      checkWord(word, indices);
    }
    
    if (newFoundWords.length > 0) {
      isWordAnimationPlayingRef.current = true;
      const wordsToAnimate = [];
      const wordsToAdd = new Set();

      // Group singular and plural words
      const wordPairs = newFoundWords.reduce((pairs, { word, indices }) => {
        const base = word.endsWith('S') ? word.slice(0, -1) : word;
        if (!pairs[base]) pairs[base] = [];
        pairs[base].push({ word, indices });
        return pairs;
      }, {});

      Object.values(wordPairs).forEach(pair => {
        if (pair.length === 2 && pair[0].word + 'S' === pair[1].word) {
          // Plural and singular found together
          wordsToAnimate.push(pair[1]); // Animate the plural
          pair.forEach(({ word }) => wordsToAdd.add(word));
        } else {
          // Single word or unrelated words
          pair.forEach(word => {
            wordsToAnimate.push(word);
            wordsToAdd.add(word.word);
          });
        }
      });

      // Animate and add points for words
      wordsToAnimate.forEach(({ word, indices }) => {
        const wordIndices = indices.slice(0, word.length);
        wordIndices.forEach((index, i) => {
          setTimeout(() => {
            setFlashingTiles([index]);
            const pointsToAdd = calculatePoints(word.length, i);
            if (word.endsWith('S') && foundWords.includes(word.slice(0, -1))) {
              // Only add points for the final 'S'
              if (i === word.length - 1) {
                setPoints(prevPoints => prevPoints + pointsToAdd);
                setMoves(prevMoves => prevMoves + pointsToAdd);
                setIsPulsing(true);
                setIsMovePulsing(true);
              }
            } else {
              setPoints(prevPoints => prevPoints + pointsToAdd);
              setMoves(prevMoves => prevMoves + pointsToAdd);
              setIsPulsing(true);
              setIsMovePulsing(true);
            }
            setTimeout(() => {
              setIsPulsing(false);
              setIsMovePulsing(false);
            }, 300);
          }, i * 350);

          setTimeout(() => {
            setFlashingTiles([]);
          }, i * 350 + 300);
        });

        // Add final whole word flash
        setTimeout(() => {
          setFlashingTiles(wordIndices);
          setTimeout(() => setFlashingTiles([]), 2000);
        }, word.length * 350 + 300);

        // Update codeface based on word length
        if (word.length >= 11) {
          triggerAssistantMessage("cool", "LEGEND!!", "jump");
        } else if (word.length >= 6) {
          triggerAssistantMessage("excited", "OHHH NICE CATCH!", "jump");
        } else {
          triggerAssistantMessage("satisfied", "YEP, THAT'S A WORD", "jump");
        }
      });

      setFoundWords(prevFoundWords => {
        const updatedWords = [...new Set([...prevFoundWords, ...wordsToAdd])];
        setNewWords([...wordsToAdd]);
        setTimeout(() => setNewWords([]), 1000);
        
        if (updatedWords.length === usedWords.length) {
          setAllWordsFound(true);
        }
        
        return updatedWords;
      });

      // Set a timeout to reset isWordAnimationPlayingRef after all animations are done
      const longestWordLength = Math.max(...newFoundWords.map(w => w.word.length));
      const totalAnimationTime = longestWordLength * 350 + 2300; // Adjust this based on your animation durations
      setTimeout(() => {
        isWordAnimationPlayingRef.current = false;
      }, totalAnimationTime);
    }

    if(isGameOverCheck) {
      setIsCheckingGameOver(false);
      if (newFoundWords.length === 0) {
        triggerAssistantMessage("game-over", "NooOOOo!!", "shake");
        setTimeout(() => setIsGameOver(true), 2500);
      } else {
        triggerAssistantMessage("saved", "PHEW, SAVED!", "jump");

        setTimeout(() => {
          triggerAssistantMessage("careful", "CAREFUL NOW...", "jump");
        }, 3000);
      }
    }

    return newFoundWords;
  };

  // Function to handle tile clicks
  const handleTileClick = (index) => {
    if (moves === 0 || allWordsFound) return; // Prevent moves when counter reaches 0 or all words are found

    const emptyIndex = puzzle.indexOf('');
    if (index === emptyIndex) return; // Don't do anything if clicking on the empty tile

    const clickedRow = Math.floor(index / 4);
    const clickedCol = index % 4;
    const emptyRow = Math.floor(emptyIndex / 4);
    const emptyCol = emptyIndex % 4;

    // Check if the clicked tile is in the same row or column as the empty tile
    if (clickedRow === emptyRow || clickedCol === emptyCol) {
      const newPuzzle = [...puzzle];
      
      if (clickedRow === emptyRow) {
        // Move tiles horizontally
        const start = Math.min(clickedCol, emptyCol);
        const end = Math.max(clickedCol, emptyCol);
        if (clickedCol < emptyCol) {
          // Move right
          for (let i = end; i > start; i--) {
            newPuzzle[clickedRow * 4 + i] = newPuzzle[clickedRow * 4 + i - 1];
          }
        } else {
          // Move left
          for (let i = start; i < end; i++) {
            newPuzzle[clickedRow * 4 + i] = newPuzzle[clickedRow * 4 + i + 1];
          }
        }
      } else {
        // Move tiles vertically
        const start = Math.min(clickedRow, emptyRow);
        const end = Math.max(clickedRow, emptyRow);
        if (clickedRow < emptyRow) {
          // Move down
          for (let i = end; i > start; i--) {
            newPuzzle[i * 4 + clickedCol] = newPuzzle[(i - 1) * 4 + clickedCol];
          }
        } else {
          // Move up
          for (let i = start; i < end; i++) {
            newPuzzle[i * 4 + clickedCol] = newPuzzle[(i + 1) * 4 + clickedCol];
          }
        }
      }
      
      // Place the empty tile at the clicked position
      newPuzzle[index] = '';
      
      setPuzzle(newPuzzle);
      setMoves(prevMoves => {
        const newMoves = prevMoves - 1;
        if (newMoves === 0) {
          // Trigger shake animation when moves reach 0
          setIsMovesShaking(true);
          setTimeout(() => setIsMovesShaking(false), 500);
          
          setIsCheckingGameOver(true);
        }
        return Math.max(newMoves, 0); // Ensure moves don't go below 0
      });
    }
  };

  // Function to initialize the puzzle
  const initializePuzzle = () => {
    setShowRules(true);
    setIsScrambling(true);
    let initialPuzzle = createPuzzle();
    setPuzzle(Array(16).fill('')); // Start with an empty grid

    let finalPuzzle;
    let initialWords;

    do {
      finalPuzzle = shufflePuzzle([...initialPuzzle]);
      initialWords = checkForInitialWords(finalPuzzle);
    } while (initialWords.length > 0);

    // Ensure the empty tile is at index 9 (third column, third row)
    const emptyIndex = finalPuzzle.indexOf('');
    if (emptyIndex !== 9) {
      [finalPuzzle[9], finalPuzzle[emptyIndex]] = [finalPuzzle[emptyIndex], finalPuzzle[9]];
    }

    // Find all possible words
    const allPossibleWords = findAllWords(finalPuzzle);
    setUsedWords(allPossibleWords);

    // Set the final puzzle without animation
    setPuzzle(finalPuzzle);

    setIsScrambling(false);
    setFoundWords([]);
    //setPoints(0);
    //setMoves(200);
    setIsGameOver(false);
    setAllWordsFound(false);
  };

  // New function to start the game animation
  const startGameAnimation = () => {
    setIsScrambling(true);
    setPuzzle(Array(16).fill('')); // Reset to empty grid
    setStartMoves(0); // Start from 0 moves
    setIsAnimatingMoves(true); // Start animating moves

    const finalPuzzle = [...puzzle]; // Use the already set puzzle
    const spiralOrder = [0, 1, 2, 3, 7, 11, 15, 14, 13, 12, 8, 4, 5, 6, 10, 9];
    
    const totalDuration = 1000;
    const intervalDuration = totalDuration / spiralOrder.length;

    spiralOrder.forEach((index, i) => {
      setTimeout(() => {
        setPuzzle(prev => {
          const newPuzzle = [...prev];
          newPuzzle[index] = finalPuzzle[index];
          return newPuzzle;
        });
      }, i * intervalDuration);
    });

    // Animate moves counter
    const startTime = Date.now();
    const animateMoves = () => {
      const elapsedTime = Date.now() - startTime;
      const progress = Math.min(elapsedTime / totalDuration, 1);
      const currentMoves = Math.floor(progress * 200);
      setStartMoves(currentMoves);

      if (progress < 1) {
        requestAnimationFrame(animateMoves);
      } else {
        setIsAnimatingMoves(false);
      }
    };
    requestAnimationFrame(animateMoves);

    setTimeout(() => {
      setIsScrambling(false);
      setIsAnimatingMoves(false);
      setIsGameStarted(true); // Set this to true when the game starts
      setIsAssistantVisible(true);
      triggerAssistantMessage("determined", "LET'S FIND SOME WORDS!", "jump");
    }, totalDuration + 100);
  };

  // Function to check for words without updating state
  const checkForInitialWords = (puzzleArray) => {
    const words = [];
    const spiralOrder = [0, 1, 2, 3, 7, 11, 15, 14, 13, 12, 8, 4, 5, 6, 10, 9];
    
    let word = '';
    for (let i = 0; i < spiralOrder.length; i++) {
      const letter = puzzleArray[spiralOrder[i]];
      if (letter === '') break;
      word += letter;
      if (word.length >= 4 && wordList.includes(word.toLowerCase())) {
        words.push(word);
      }
    }
    
    return words;
  };

  // Function to find all possible words on the grid
  const findAllWords = (puzzleArray) => {
    const allWords = new Set();
    const letters = puzzleArray.filter(letter => letter !== '').join('');

    // Helper function to check if a word can be formed from available letters
    const canFormWord = (word, availableLetters) => {
      const letterCount = {};
      for (const letter of availableLetters) {
        letterCount[letter] = (letterCount[letter] || 0) + 1;
      }
      for (const letter of word) {
        if (!letterCount[letter]) return false;
        letterCount[letter]--;
      }
      return true;
    };

    // Check each word in the wordList
    for (const word of wordList) {
      if (word.length >= 4 && word.length <= 15 && canFormWord(word.toUpperCase(), letters)) {
        allWords.add(word.toUpperCase());
      }
    }

    return Array.from(allWords);
  };

  // Check for words when moves change
  useEffect(() => {
    if (isGameStarted) {
      checkForWords(puzzle, isCheckingGameOver);
    }
  }, [moves, isGameStarted]);

  // Update the document title when the component mounts
  useEffect(() => {
    document.title = "SLIDLE";
  }, []);

  // Initialize the puzzle when the component mounts
  useEffect(() => {
    initializePuzzle();
  }, []);

  const toggleDarkMode = () => {
    setIsDarkMode(prevMode => {
      const newMode = !prevMode;
      localStorage.setItem('isDarkMode', JSON.stringify(newMode));
      return newMode;
    });
  };

  useEffect(() => {
    document.body.classList.toggle('dark-mode', isDarkMode);
  }, [isDarkMode]);

  useEffect(() => {
    if (!isAnimatingMoves && !isWordAnimationPlayingRef.current) {
      if (moves === 30 || moves === 10) {
        if (!isMovePulsing) {
          setIsMovesShaking(true);
          setTimeout(() => setIsMovesShaking(false), 500);
        }
      }
    }
  }, [moves, isMovePulsing, isAnimatingMoves]);

  useEffect(() => {
    if (allWordsFound) {
      setTimeout(() => setIsGameOver(true), 2000);
    }
  }, [allWordsFound]);

  const triggerJumpAnimation = () => {
    setIsCodefaceJumping(true);
    setTimeout(() => setIsCodefaceJumping(false), 400);
  };

  const triggerShakeAnimation = () => {
    setIsCodefaceShaking(true);
    setTimeout(() => setIsCodefaceShaking(false), 500);
  };

  const triggerAssistantMessage = (codeface, text, animation) => {
    updateAssistantText('');
    setCurrentCodeface(codeface);
    animation === "jump" ? triggerJumpAnimation() : triggerShakeAnimation();
    setTimeout(() => {
      updateAssistantText(text);
    }, animation === "jump" ? 400 : 500);
  }

  const updateAssistantText = (text) => {
    fullTextRef.current = text;
    currentIndexRef.current = 0;
    setAssistantText('');
    // Trigger the effect immediately
    setTimeout(() => setAssistantText(" "), 0);
  };

  useEffect(() => {
    if (currentIndexRef.current < fullTextRef.current.length) {
      const timeoutId = setTimeout(() => {
        setAssistantText(fullTextRef.current.slice(0, currentIndexRef.current + 1));
        currentIndexRef.current += 1;
      }, 20);
      return () => clearTimeout(timeoutId);
    }
  }, [assistantText]);

  useEffect(() => {
    if (!isAnimatingMoves && !isWordAnimationPlayingRef.current) {
      if (moves === 30) {
        triggerAssistantMessage("fear", "WE'RE LOW ON GAS!", "shake");
      } else if (moves === 10) {
        triggerAssistantMessage("terror", "JUST A FEW MOVES!", "shake");
      }
    }
  }, [moves, isAnimatingMoves]);

  const [animatedIndex, setAnimatedIndex] = useState(-1);
  const [highlightedIndices, setHighlightedIndices] = useState([]);

  useEffect(() => {
    if (showRules) {
      let index = 0;
      const spiralOrder = [0, 1, 2, 3, 7, 11, 15, 14, 13, 12, 8, 4, 5, 6, 10, 9];
      const interval = setInterval(() => {
        setAnimatedIndex(index);
        setTimeout(() => {
          setHighlightedIndices(prev => [...prev, spiralOrder[index-1]]);
        }, 200); // Delay highlighting until after pulse animation
        index++;
        if (index === 11) { // "INTELLIGENT" has 11 letters
          clearInterval(interval);
          setTimeout(() => setAnimatedIndex(-1), 200);
        }
      }, 300); // Adjust timing as needed

      return () => clearInterval(interval);
    }
  }, [showRules]);

  return (
    <div className={`App ${isDarkMode ? 'dark-mode' : 'light-mode'}`}>
      <button className="dark-mode-toggle" onClick={toggleDarkMode}>◐</button>
      <h1>SLID<span className="lower-letter">L</span>E</h1>
      <div className="game-container">
        <Board 
          puzzle={puzzle} 
          onTileClick={handleTileClick} 
          isScrambling={isScrambling}
          flashingTiles={flashingTiles}
        />
      </div>
      
      <div className="counters">
        <div>
          {(startMoves < 200) && (
            <strong>Moves Left: <span className={`counter moves`}>{startMoves}</span></strong>
          )}
          {(startMoves === 200) && (
            <strong>Moves Left: <span className={`counter moves ${!isAnimatingMoves && moves <= 30 ? 'low-moves' : ''} ${!isAnimatingMoves && moves <= 10 ? 'very-low-moves' : ''} ${!isAnimatingMoves && (isMovePulsing ? 'pulsing' : isMovesShaking ? 'shaking' : '')}`}>{moves}</span></strong>
          )}
        </div>
        <div className="points-container">
          <strong>Points: </strong>
          <span className={`counter points ${isPulsing ? 'pulsing' : ''}`}>{points}</span>
        </div>
      </div>

      <div className="assistant-wrapper">
        <div className={`assistant-container ${isAssistantVisible ? 'visible' : ''}`}>
          <div className={`assistant-codeface ${isCodefaceJumping ? 'jumping' : ''} ${isCodefaceShaking ? 'shaking' : ''}`}>
            {currentCodeface === "determined" && <div className="codeface-determined">(ง'̀-'́)ง</div>}
            {currentCodeface === "satisfied" && <div className="codeface-satisfied">(~˘▾˘)~</div>}
            {currentCodeface === "excited" && <div className="codeface-excited">\(♥‿♥)/</div>}
            {currentCodeface === "cool" && <div className="codeface-cool">(▀̿Ĺ̯▀̿ ̿)</div>}
            {currentCodeface === "fear" && <div className="codeface-fear">(ಥ﹏ಥ)</div>}
            {currentCodeface === "terror" && <div className="codeface-terror">(;´༎ຶД༎ຶ`)</div>}
            {currentCodeface === "game-over" && <div className="codeface-game-over">(ノಠ益ಠ)ノ彡┻━┻</div>}
            {currentCodeface === "saved" && <div className="codeface-saved">┬─┬ノ( º _ ºノ)</div>}
            {currentCodeface === "careful" && <div className="codeface-careful">ಠ_ಠ'</div>}

            {currentCodeface === "rand1" && <div className="codeface-rand1">ლ(ಠ益ಠლ)</div>}
            {currentCodeface === "rand2" && <div className="codeface-rand2">( ⚆ _ ⚆)</div>}
            {currentCodeface === "rand3" && <div className="codeface-rand3">(°ロ°)☝</div>}
            {currentCodeface === "rand4" && <div className="codeface-rand4">╚(•◡• )=┐</div>}
            {currentCodeface === "rand5" && <div className="codeface-rand5">(ﾉ◕ヮ◕)ﾉ*･ﾟ✧</div>}
            {currentCodeface === "rand6" && <div className="codeface-rand6">(▰˘◡˘▰)</div>}
            {currentCodeface === "rand7" && <div className="codeface-rand7">( '¬_¬)ง◔</div>}
            {currentCodeface === "rand8" && <div className="codeface-rand8">( •̀ᴗ•́)و ̑̑</div>}
          </div>
          <div className="assistant-text">
            <div className="assistant-text-content">{assistantText}</div>
          </div>
        </div>
      </div>
      
      <div className="found-words">
        {foundWords.map((word, index) => (
          <PulsingWord 
            key={`found-${index}`} 
            word={word} 
            isPulsing={newWords.includes(word)}
          />
        ))}
      </div>
      
      {(isGameOver || allWordsFound) && (
        <div className="game-over-overlay">
          <div className="game-over-content">
            <h2>{allWordsFound ? "CONGRATULATIONS!" : "WELL DONE!"}</h2>
            <p>You found <strong>{foundWords.length}</strong> words for a total of <strong>{points}</strong> points</p>
            {allWordsFound && <p>You found all possible words!</p>}
            <div><span className="game-over-assistant">(˶˙ᵕ˙˶)ﾉﾞ</span> <span className="assistant-text">SEE YOU TOMORROW!</span></div> 
            <div className="final-words">
              {foundWords.map((word, index) => (
                <PulsingWord 
                  key={`final-${index}`} 
                  word={word} 
                  isPulsing={newWords.includes(word)}
                />
              ))}
            </div>
          </div>
        </div>
      )}
      
      {showRules && (
        <div className="rules-overlay">
          <div className="rules-content">
            <h2>HOW TO PLAY</h2>
            <StaticGrid 
              activeIndex={animatedIndex}
              highlightedIndices={highlightedIndices}
            />
            <p>1. Slide tiles to form words following the spiral pattern.</p>
            <p>2. Words must be at least 4 letters long.</p>
            <p>3. You have 200 moves to find as many words as possible.</p>
            <p>4. Longer words score more points and gain more moves!</p>
            <button onClick={() => {
              setShowRules(false);
              startGameAnimation();
            }}>(☞ಠ_ಠ)☞ <span>LET'S SLIDE!</span></button>
          </div>
        </div>
      )}
      
      { /*
      <div className="debug-section">
        <h3>DEBUG: List of Loaded Words</h3>
        <p>Current words: {usedWords.join(', ')}</p>
        <p>Total words in list: {wordList.length}</p>
        <p>Valid words (4-15 letters): { 
          wordList.filter(word => 
            word.length >= 4 && 
            word.length <= 15
          ).length
        }</p>
        <p>All words: {wordList.join(', ')}</p>
      </div>
      */ }
      
    </div>
  );
}

export default App;
