import React from 'react';
import './StaticGrid.css';

const StaticGrid = ({ activeIndex, highlightedIndices }) => {
  const demoWord = ['I', 'N', 'T', 'E', 'L', 'L', 'I', 'G', 'E', 'N', 'T', 'Q', 'X', 'A', 'F', ''];
  const spiralOrder = [0, 1, 2, 3, 7, 11, 15, 14, 13, 12, 8, 4, 5, 6, 10, 9];
  const grid = Array(16).fill('');

  // Fill the grid with the demo word
  demoWord.forEach((letter, index) => {
    grid[spiralOrder[index]] = letter;
  });

  return (
    <div className="static-grid">
      {grid.map((letter, index) => (
        <div 
          key={index} 
          className={`static-tile 
            ${highlightedIndices.includes(index) ? 'highlighted' : ''} 
            ${letter === '' ? 'empty' : ''} 
            ${spiralOrder[activeIndex] === index ? 'pulse' : ''}`}
        >
          {letter}
        </div>
      ))}
    </div>
  );
};

export default StaticGrid;