import React from 'react';
import './Tile.css';

function Tile({ letter, onClick, isFlashing, isScrambling }) {
  return (
    <div
      className={`tile ${letter === '' ? 'empty' : ''} ${isFlashing ? 'flashing' : ''} ${isScrambling ? 'scrambling' : ''}`}
      onClick={onClick}
    >
      {letter}
    </div>
  );
}

export default Tile;